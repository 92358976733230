import {ENDPOINTS} from '../../constants/ApiEndpoints';
import React, {useRef} from 'react';
import {User, UserState} from '../../interfaces/User';
import {App, Space, Tag} from 'antd';
import Column from 'antd/es/table/Column';
import {AutoFetchTable} from '../../components/table/AutoFetchTable';
import {InviteButton} from './components/InviteButton';
import {useAuth} from '../../auth/AuthProvider';
import {EditUserModal} from './components/EditUserModal';
import axios from 'axios';

interface TableData {
    key: string,
    name: string,
    email: string,
    permissions: string,
    user: User,

    editUser: () => void;
    blockUser: () => void;
    unblockUser: () => void;
    resetPassword: () => void;
}

export const ManageAdmins = () => {
    const auth = useAuth();

    const {notification, modal} = App.useApp();
    const invalidateCallback = useRef<() => void>();


    const displayError = (msg: string, e?: any) => {
        console.log(e);

        notification.error({
            message: msg,
        });
    };


    const transformUser = (c: User) => {
        return {
            name: c.firstName + ' ' + c.lastName,
            companyName: (c.company?.name) || '?',
            permissions: (c.permissionGroup?.name) || '?',
            ...c
        };
    };

    const invalidate = () => {
        if (invalidateCallback.current) invalidateCallback.current();
    };

    const withConfirm = (text: string, errorText: string, action: (u: User) => Promise<void>, content?: string) => (u: User) => {
        modal.confirm({
            title: text,
            content: content ?? `${u.firstName} ${u.lastName}`,
            okText: 'Ja',
            cancelText: 'Abbrechen',
            onOk: () => {
                action(u)
                    .then(invalidate)
                    .catch((e) => {
                        displayError(errorText, e);
                    });
            }
        });
    };

    const blockUser = withConfirm(
        'Nutzer blockieren?',
        'Fehler beim blokieren des Nutzers.',
        async (user: User) => {
            await axios.put(ENDPOINTS.blockUser(user), {});
        }
    );

    const unblockUser = withConfirm(
        'Nutzer freigeben?',
        'Fehler beim freigeben des Nutzers.',
        async (user: User) => {
            await axios.put(ENDPOINTS.unblockUser(user), {});
        }
    );

    const resetPassword = withConfirm(
        'Passwort zurücksetzen?',
        'Fehler beim zurúcksetzen des Passwortes.',
        async (user: User) => {
            await axios.put(ENDPOINTS.blockUser(user), {});
            notification.info({
                message: 'E-Mail wurde versendet.'
            });
        },
        'Der Nutzer erhält eine E-Mail.'
    );

    if (!auth.user) {
        throw new Error('User not logged in?');
    }

    return <Space direction="vertical" size="large" style={{width: '100%'}}>
        <h1>Administratoren</h1>

        <Space style={{width: '100%', justifyContent: 'flex-end'}}>
            <InviteButton invalidateCallback={invalidate}/>
        </Space>

        <AutoFetchTable
            url={ENDPOINTS.adminListUsers}
            modal={(item, onClose) => <EditUserModal user={item} onAbort={onClose} onChange={onClose}/>}
            transformFunction={transformUser}
            invalidateCallback={invalidateCallback}
            query={{
                isAdmin: 'true',
            }}
            searchItems={[
                {value: 'searchMail', label: 'E-Mail'},
                {value: 'searchName', label: 'Name'},
                {value: 'searchCompany', label: 'Unternehmen'},
            ]}
            extraActions={[
                {
                    label: 'Freigeben',
                    callback: unblockUser,
                    condition: (u) => u.userState == UserState.Blocked,
                },
                {
                    label: 'Blockieren',
                    callback: blockUser,
                    condition: (u) => u.userState != UserState.Blocked,
                },
                {
                    label: 'Passwort zurücksetzen',
                    callback: resetPassword,
                    condition: () => true,
                },
            ]}

        >
            <Column title="Name" dataIndex="name" key="name" sorter={true}/>
            <Column title="E-Mail" dataIndex="mail" key="mail" sorter={true}/>
            <Column title="Abteilung" dataIndex="department" key="department" sorter={true}/>
            <Column title="Berechtigungsgruppe" dataIndex="permissions" key="permissionGroup.name" sorter={true}
                    render={(_: any, user: TableData) => (
                        <><Tag>{user.permissions}</Tag>  </>
                    )}
            />
            <Column title="Status" dataIndex="state" key="userState" sorter={true}
                    render={(_: any, user: User) => {
                        if (user.userState == UserState.Active) {
                            return <Tag color="green">Aktiv</Tag>;
                        }
                        if (user.userState == UserState.Blocked) {
                            return <Tag color="red">Gesperrt</Tag>;
                        }
                        if (user.userState == UserState.Invited) {
                            return <Tag color="gray">Eingeladen</Tag>;
                        }
                        return <>???</>;
                    }}/>

        </AutoFetchTable>

    </Space>;
};
